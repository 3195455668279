"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var xstream_1 = require("xstream");
var dropRepeats_1 = require("xstream/extra/dropRepeats");
var adapt_1 = require("@cycle/run/lib/adapt");
var extStorageProducer = {
    start: function (listener) {
        this.sendReq = function (storage) {
            listener.next({
                target: 'local',
                key: storage.key,
                value: storage.newValue
            });
        };
        window.addEventListener('storage', this.sendReq);
    },
    stop: function () {
        window.removeEventListener('storage', this.sendReq);
    }
};
var extStorage$ = typeof window !== "undefined"
    ? xstream_1.default.create(extStorageProducer)
    : xstream_1.default.never();
function getStorage$(request$, type) {
    if (type === 'local') {
        return xstream_1.default.merge(request$.filter(function (req) { return !req.target || req.target === 'local'; }), extStorage$);
    }
    else {
        return request$.filter(function (req) { return req.target === 'session'; });
    }
}
function storageKey(n, request$, type) {
    if (type === void 0) { type = 'local'; }
    var storage$ = getStorage$(request$, type);
    var key = type === "local" ? localStorage.key(n) : sessionStorage.key(n);
    return storage$
        .filter(function (req) { return req.key === key; })
        .map(function (req) { return req.key; })
        .startWith(key)
        .compose(dropRepeats_1.default());
}
function storageGetItem(key, request$, type) {
    if (type === void 0) { type = 'local'; }
    var storage$ = getStorage$(request$, type);
    var storageObj = type === "local" ? localStorage : sessionStorage;
    return storage$
        .filter(function (req) { return req.key === key; })
        .map(function (req) { return req.value; })
        .startWith(storageObj.getItem(key));
}
function getResponseObj(request$, type) {
    if (type === void 0) { type = 'local'; }
    return {
        // Function returning stream of the nth key.
        key: function (n) {
            return adapt_1.adapt(storageKey(n, request$, type));
        },
        // Function returning stream of item values.
        getItem: function (key) {
            return adapt_1.adapt(storageGetItem(key, request$, type));
        },
    };
}
exports.default = getResponseObj;
