"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @function writeToStore
 * @description
 * A universal write function for localStorage and sessionStorage.
 * @param {object} request - the storage request object
 * @param {string} request.target - a string determines which storage to use
 * @param {string} request.action - a string determines the write action
 * @param {string} request.key - the key of a storage item
 * @param {string} request.value - the value of a storage item
 */
function writeToStore(request) {
    var _a = request.target, target = _a === void 0 ? 'local' : _a, _b = request.action, action = _b === void 0 ? 'setItem' : _b;
    var key = request.key;
    var value = request.value;
    // Determine the storage target.
    var storage = target === "local" ? localStorage : sessionStorage;
    // Execute the storage action and pass arguments if they were defined.
    storage[action](key, value);
}
exports.default = writeToStore;
