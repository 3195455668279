"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var util_1 = require("./util");
function default_1(request$) {
    return {
        // For localStorage.
        get local() {
            return util_1.default(request$);
        },
        // For sessionStorage.
        get session() {
            return util_1.default(request$, 'session');
        },
    };
}
exports.default = default_1;
